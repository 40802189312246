import { Popconfirm, Typography, Input, Button, List } from "antd"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { marked } from "marked"

import LanguageSelector from "components/LanguageSelector"

import useTableData from "hooks/useTableData"
import useUpdateEffect from "hooks/useUpdateEffect"
import supabase from "lib/supabase"
import { useCallback, useMemo, useState } from "react"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import useToggle from "hooks/useToggle"

const { Title } = Typography;

function ArticleForm({ formTitle, article, caseId, onSaved, onCancelled }) {

    const [ saving, setSaving ] = useState(false);
    const [ title, setTitle ] = useState(() => article?.title || "");
    const [ text, setText ] = useState(() => article?.text || "");
    const [ languageId, setLanguageId ] = useState(() => article?.language || null);
    const [ position, setPosition ] = useState(() => article?.position || 0);

    const html = useMemo(
        () => marked.parse(text),
        [ text ]
    );

    useUpdateEffect(
        () => {
            setTitle(article?.title || "");
            setText(article?.text || "");
            setLanguageId(article?.language || null);
            setPosition(article?.position || 0);
        },
        [ article ]
    );

    const handleTitleChange = useCallback(
        (e) => setTitle(e.target.value),
        []
    );

    const handleTextChange = useCallback(
        (e) => setText(e.target.value),
        []
    );

    const handlePositionChange = useCallback(
        (e) => setPosition(e.target.value),
        []
    );

    const onSaveClick = useCallback(
        async () => {
            setSaving(true);
            const payload = {
                title,
                text,
                language: languageId,
                case_id: caseId,
                position
            };
            if (article && article.id) {
                await supabase.from("article").update(payload).eq("id", article.id);
            }
            else {
                await supabase.from("article").insert(payload);
            }
            setSaving(false);
            setText("");
            setTitle("");
            setLanguageId(null);
            onSaved && onSaved();
        },
        [ caseId, article, title, text, languageId, position, onSaved ]
    );


    return (
        <div className="case-article-form">
            { formTitle && <Title level={ 5 }>{ formTitle }</Title> }
            <LanguageSelector onChange={ setLanguageId } value={ languageId }/>
            <br/><br/>
            <Input
                placeholder="Заголовок" 
                disabled={ saving }
                value={ title }
                onChange={ handleTitleChange }/>
            <br/><br/>
            <Input
                placeholder="Позиция" 
                disabled={ saving }
                value={ position }
                onChange={ handlePositionChange }/>
            <br/><br/>
            <div className="case-article-form-2col">
                <div>
                    <Input.TextArea 
                        placeholder="Текст"
                        disabled={ saving }
                        value={ text }
                        onChange={ handleTextChange }/>
                </div>
                <div dangerouslySetInnerHTML={{ __html: html }}/>
            </div>
            <br/>
            <Button 
                type="primary" 
                disabled={ saving }
                loading={ saving }
                onClick={ onSaveClick }
                children="Сохранить"/>
            <Button 
                type="secondary" 
                children="Отменить" 
                disabled={ saving }
                onClick={ onCancelled }/>
        </div>
    )

}


function ArticlesListItem({ article }) {

    const [ editMode, toggleEditMode ] = useToggle();

    const onDelete = useCallback(
        async () => {
            await supabase.from("article").delete().eq("id", article.id);
        },
        [ article ]
    );

    const onEditClick = useSwallowEventCallback(
        () => toggleEditMode(),
        []
    );

    return (
        <List.Item>
            { editMode ? 
                <ArticleForm 
                    article={ article } 
                    caseId={ article.case_id }
                    onCancelled={ toggleEditMode }
                    onSaved={ toggleEditMode }/> :
                <>
                    <div>{ article.position } : { article.title }</div>
                    <a href="/#" onClick={ onEditClick }>
                        <EditOutlined/>
                    </a>
                    <Popconfirm onConfirm={ onDelete } title="Вы уверены?">
                        <DeleteOutlined/>
                    </Popconfirm>
                </> }
        </List.Item>
    )
}

function ArticlesList({ caseId }) {
    const data = useTableData("article");
    const articles = useMemo(
        () => data.filter(a => a.case_id === caseId),
        [ caseId, data ]
    );

    return (
        <List bordered className="case-articles-list">
            { articles.map(a => (
                <ArticlesListItem key={ a.id } article={ a }/>
            ))}
        </List>
    )
}

function Articles({ caseId }) {

    const [ showAddForm, toggleAddForm ] = useToggle();

    return (
        <div className="case-articles">
            { !showAddForm && <Button type="primary" onClick={ toggleAddForm } children="Добавить"/> }
            { showAddForm && <ArticleForm caseId={ caseId } onCancelled={ toggleAddForm }/> }
            <br/><br/>
            <ArticlesList caseId={ caseId }/>
        </div>
    )
}

export default Articles