import { useCallback, useMemo, useState } from "react"
import { Input, Button } from "antd"

import useTableData from "hooks/useTableData"
import useUpdateEffect from "hooks/useUpdateEffect";
import supabase from "lib/supabase";



function Info({ caseId }) {

    const caseData = useTableData("case");
    const c = useMemo(
        () => caseData.find(c => c.id === caseId),
        [ caseId, caseData ]
    );

    const [ saving, setSaving ] = useState(false);
    const [ nameRussian, setNameRussian ] = useState(() => c?.name?.russian || "");
    const [ nameEnglish, setNameEnglish ] = useState(() => c?.name?.english || "");
    const [ descriptionRussian, setDescriptionRussian ] = useState(() => c?.description?.russian || "");
    const [ descriptionEnglish, setDescriptionEnglish ] = useState(() => c?.description?.english || "");

    useUpdateEffect(
        () => {
            setNameRussian(c?.name?.russian || "");
            setNameEnglish(c?.name?.english || "");
            setDescriptionRussian(c?.description?.russian || "");
            setDescriptionEnglish(c?.description?.english || "");
        },
        [ c ]
    );

    const handleNameRussianChange = useCallback(
        (e) => setNameRussian(e.target.value),
        []
    );
    const handleNameEnglishChange = useCallback(
        (e) => setNameEnglish(e.target.value),
        []
    );
    const handleDescriptionRussianChange = useCallback(
        (e) => setDescriptionRussian(e.target.value),
        []
    );
    const handleDescriptionEnglishChange = useCallback(
        (e) => setDescriptionEnglish(e.target.value),
        []
    );

    const onSaveClick = useCallback(
        async () => {
            setSaving(true);

            const payload = {
                name: {
                    russian: nameRussian,
                    english: nameEnglish
                },
                description: {
                    russian: descriptionRussian,
                    english: descriptionEnglish
                }
            }

            await supabase.from("case").update(payload).eq("id", caseId);

            setSaving(false);
        },
        [ nameRussian, nameEnglish, descriptionRussian, descriptionEnglish, caseId ]
    );

    return (
        <div className="case-info">
            <Input 
                placeholder="Название"
                disabled={ saving }
                value={ nameRussian }
                onChange={ handleNameRussianChange }/>
            <br/><br/>
            <Input 
                placeholder="Описание"
                disabled={ saving }
                value={ nameEnglish }
                onChange={ handleNameEnglishChange }/>
            <br/><br/>
            <Input 
                placeholder="Name"
                disabled={ saving }
                value={ descriptionRussian }
                onChange={ handleDescriptionRussianChange }/>
            <br/><br/>
            <Input 
                placeholder="Description"
                disabled={ saving }
                value={ descriptionEnglish }
                onChange={ handleDescriptionEnglishChange }/>
            <br/><br/>
            <Button 
                type="primary" 
                children="Сохранить"
                disabled={ saving }
                loading={ saving }
                onClick={ onSaveClick }/>
        </div>
    )
}

export default Info