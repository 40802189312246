import { useState } from "react"

import ItemsList from "components/ItemsList"
import WordPageHeader from "components/word/WordPageHeader"
import CaseWord from "components/word/CaseWord"


function Numerals() {

    const [ wordId, setWordId ] = useState(null);

    return (
        <div className="page page-nouns">
            <WordPageHeader title="Числительные" table="numeral"/>
            <div className="page-2col">
                <div>
                    <ItemsList  
                        size="normal"
                        table="numeral" 
                        selectedId={ wordId } 
                        onSelect={ setWordId }/>
                </div>
                <CaseWord 
                    wordId={ wordId } 
                    table="numeral"
                    withPlural={ true }
                    withDeclension={ false }/>
            </div>
        </div>
    )
}

export default Numerals