import { Menu } from "antd"
import useTableData from "hooks/useTableData"
import { useCallback, useMemo } from "react";

function CaseTabs({ currentTab, onChange, 
                    before = [], after = [], 
                    disabled = true, showGeneralInfo = false }) {

    const cases = useTableData("case");
    const tabs = useMemo(
        () => [
            ...before,
            showGeneralInfo ? 
                {
                    key: "info",
                    label: "Общая информация"
                } : 
                null,
            ...cases.map(c => {
                return { key: c.id, label: c.name.russian, disabled }
            }),
            ...after
        ].filter(t => !!t),
        [ cases, before, after, disabled, showGeneralInfo ]
    );

    const onMenuClick = useCallback(
        ({ key }) => onChange(key),
        [ onChange ]
    );

    
    return (
        <Menu 
            className="case-tabs"
            style={{ flex: 0, minWidth: 0 }}
            mode="horizontal"
            items={ tabs } 
            onClick={ onMenuClick } 
            defaultSelectedKeys={ cases[0] ? [ cases[0].id ] : [] }
            selectedKeys={ [ currentTab ] }/>
    )
}

export default CaseTabs