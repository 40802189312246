import { useCallback, useMemo, useState } from "react";
import { List, Menu, Typography } from "antd"

import CaseQuestions from "components/case/Questions"
import CaseGroups from "components/case/Groups"
import CaseArticles from "components/case/Articles"
import CaseInfo from "components/case/Info"

import useTableData from "hooks/useTableData"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"

const { Title } = Typography;

function Case({ id }) {

    const data = useTableData("case");
    const [ page, setPage ] = useState("questions");
    const c = useMemo(
        () => data.find(c => c.id === id),
        [ id, data ]
    );
    const menu = useMemo(
        () => [
            {
                key: "info",
                label: "Общая информация"
            },
            {
                key: "questions",
                label: "Отвечает на вопросы"
            },
            {
                key: "groups",
                label: "Группы слов"
            },
            {
                key: "articles",
                label: "Статьи"
            }
        ],
        []
    );
    const onMenuClick = useCallback(
        ({ key }) => setPage(key),
        []
    );

    return (
        <div className="case">
            <Title level={ 4 }>{ c.name.russian }</Title>
            <Menu 
                items={ menu } 
                mode="horizontal"
                selectedKeys={[page]}
                onClick={ onMenuClick }/>

            { page === "info" && <CaseInfo caseId={ id }/> }
            { page === "questions" && <CaseQuestions caseId={ id }/> }
            { page === "groups" && <CaseGroups caseId={ id }/> }
            { page === "articles" && <CaseArticles caseId={ id }/> }
        </div>
    )
}

function CaseListItem({ c, onSelect, selected }) {

    const onClick = useSwallowEventCallback(
        () => onSelect(c.key),
        [ onSelect, c ]
    );

    return (
        <List.Item className={ selected ? "active" : "" }>
            <a href="/#" onClick={ onClick }>{ c.label }</a>
        </List.Item>
    )
}

function CaseList({ onSelect, selectedId }) {
    const data = useTableData("case");
    const cases = useMemo(
        () => data.map(c => ({
            key: c.id,
            label: c.name.russian
        })),
        [ data ]
    );

    return (
        <List className="case-list">
            { cases.map(c => (
                <CaseListItem 
                    key={ c.key } 
                    c={ c }
                    onSelect={ onSelect }
                    selected={ selectedId === c.key }/>
            ))}
        </List>
    )
}

function Cases() {

    const [ caseId, setCaseId ] = useState(null);

    return (
        <div className="page page-cases">
            <div className="page-header">
                <Title level={ 3 }>Падежи</Title>
            </div>
            <div className="page-2col">
                <CaseList onSelect={ setCaseId } selectedId={ caseId }/>
                { caseId && <Case id={ caseId }/>}
            </div>
        </div>
    )
}

export default Cases