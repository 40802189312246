import { useMemo } from "react"
import { Select } from "antd"

import useTableData from "hooks/useTableData"

function DeclensionSelector({ value, onChange, ...rest }) {
    const declensions = useTableData("declension");
    const declensionOptions = useMemo(
        () => declensions.map(d => ({ value: d.id, label: d.name.russian })),
        [ declensions ]
    );

    return (
        <Select 
            placeholder="Склонение"
            value={ value }
            options={ declensionOptions } 
            onChange={ onChange }
            { ...rest }/>
    )
}

export default DeclensionSelector