import { Select } from "antd"
import useTableData from "hooks/useTableData"
import { useMemo } from "react";


function WordSelector({ value, onChange, ...rest }) {

    const data = useTableData("noun");
    const caseData = useTableData("noun_case");
    const words = useMemo(
        () => data.map(w => ({
            value: w.id,
            label: caseData.find(wc => wc.noun_id === w.id && wc.case_id === "nominative")?.single?.russian
        })),
        [ data, caseData ]
    );

    return (
        <Select 
            value={ value }
            onChange={ onChange }
            options={ words }
            className="word-selector"
            { ...rest }/>
    )
}

export default WordSelector