import { useState } from "react"

import ItemsList from "components/ItemsList"
import WordPageHeader from "components/word/WordPageHeader"
import CaseWord from "components/word/CaseWord"


function Nouns() {

    const [ wordId, setWordId ] = useState(null);

    return (
        <div className="page page-nouns">
            <WordPageHeader title="Существительные" table="noun"/>
            <div className="page-2col">
                <div>
                    <ItemsList  
                        size="normal"
                        table="noun" 
                        selectedId={ wordId } 
                        onSelect={ setWordId }/>
                </div>
                <CaseWord wordId={ wordId } table="noun"/>
            </div>
        </div>
    )
}

export default Nouns