import { Popconfirm, Typography, Input, Button, List, Tag } from "antd"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"

import PrepostpositionSelector from "components/PrepostpositionSelector"
import QuestionTypeSelector from "components/case/QuestionTypeSelector"
import useTableData from "hooks/useTableData"
import useUpdateEffect from "hooks/useUpdateEffect"
import supabase from "lib/supabase"
import { useCallback, useMemo, useState } from "react"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import useToggle from "hooks/useToggle"

const { Title } = Typography;

function getDefaultData(question) {
    return {
        question: {
            russian: "",
            egnlish: "",
            ...question?.question
        },
        comment: {
            russian: "",
            egnlish: "",
            ...question?.comment
        }
    }
}

function QuestionForm({ title, caseId, question, onSaved, onCancelled }) {

    const [ saving, setSaving ] = useState(false);
    const [ type, setType ] = useState(() => question?.type);
    const [ ppId, setPpId ] = useState(() => question?.prepostposition_id);
    const [ data, setData ] = useState(() => getDefaultData(question));

    useUpdateEffect(
        () => {
            setData(getDefaultData(question));
            setType(question?.type);
            setPpId(question?.prepostposition_id);
        },
        [ question ]
    )

    const onQChange = useCallback(
        (name, value) => {
            setData(prev => ({
                ...prev,
                question: {
                    ...prev.question,
                    [name]: value
                }
            }))
        },
        []
    );
    const onCChange = useCallback(
        (name, value) => {
            setData(prev => ({
                ...prev,
                comment: {
                    ...prev.comment,
                    [name]: value
                }
            }))
        },
        []
    );

    const handleQRussianChange = useCallback(
        (e) => onQChange("russian", e.target.value),
        [ onQChange ]
    );
    const handleQEnglishChange = useCallback(
        (e) => onQChange("english", e.target.value),
        [ onQChange ]
    );

    const handleCRussianChange = useCallback(
        (e) => onCChange("russian", e.target.value),
        [ onCChange ]
    );
    const handleCEnglishChange = useCallback(
        (e) => onCChange("english", e.target.value),
        [ onCChange ]
    );

    const onSaveClick = useCallback(
        async () => {
            setSaving(true);

            const payload = {
                case_id: caseId,
                question: data.question,
                comment: data.comment,
                type: type,
                prepostposition_id: ppId
            };
            if (question && question.id) {  
                await supabase.from("case_question").update(payload).eq("id", question.id);
            }
            else {
                await supabase.from("case_question").insert(payload);
            }

            setData({ question: {}, comment: {}});
            setType(null);
            setPpId(null);
            setSaving(false);
            onSaved && onSaved();
        },
        [ question, data, type, ppId, onSaved, caseId ]
    );

    const onCancelClick = useCallback(
        () => onCancelled && onCancelled(),
        [ onCancelled ]
    );

    return (
        <div className="case-question-form">
            { title && <Title level={ 5 }>{ title }</Title> }
            <QuestionTypeSelector 
                allowClear 
                value={ type } 
                disabled={ saving }
                onChange={ setType }/>
            <br/><br/>
            <PrepostpositionSelector 
                allowClear 
                value={ ppId } 
                disabled={ saving }
                onChange={ setPpId }/>
            <br/><br/>
            <Input
                placeholder="Вопрос"
                value={ data.question.russian }
                onChange={ handleQRussianChange }
                disabled={ saving }/>
            <br/><br/>
            <Input
                placeholder="Комментарий"
                value={ data.comment.russian }
                onChange={ handleCRussianChange }
                disabled={ saving }/>
            <br/><br/>
            <Input
                placeholder="Question"
                value={ data.question.english }
                onChange={ handleQEnglishChange }
                disabled={ saving }/>
            <br/><br/>
            <Input
                placeholder="Comment"
                value={ data.comment.english }
                onChange={ handleCEnglishChange }
                disabled={ saving }/>
            <br/><br/>
            <Button 
                type="primary" 
                onClick={ onSaveClick }
                loading={ saving }
                disabled={ saving }>Сохранить</Button>
            <Button 
                type="secondary" 
                onClick={ onCancelClick }
                disabled={ saving }>Отмена</Button>
        </div>
    )
}

function CaseQuestionsListItem({ caseId, question }) {

    const [ editMode, toggleEditMode ] = useToggle(false);

    const onEditClick = useSwallowEventCallback(
        () => toggleEditMode(),
        []
    );

    const onDelete = useCallback(
        async () => {
            await supabase.from("case_question").delete().eq('id', question.id);
        },
        [ question ]
    );

    return (
        <List.Item>
            { editMode ? 
                <QuestionForm 
                    question={ question } 
                    caseId={ caseId }
                    onCancelled={ toggleEditMode }
                    onSaved={ toggleEditMode }/> :
                <>
                <div>
                    { question.question.russian }
                    { question.comment.russian ? " (" + question.comment.russian + ")" : null }
                </div>
                { question.type && <Tag>{ question.type }</Tag> }
                { question.prepostposition_id && <Tag>{ question.prepostposition_id }</Tag> }
                <a href="/#" onClick={ onEditClick }>
                    <EditOutlined/>
                </a>
                <Popconfirm title="Вы уверены?" onConfirm={ onDelete }>
                    <DeleteOutlined/>
                </Popconfirm>
                </>  }
        </List.Item>
    )
}

function CaseQuestionsList({ caseId }) {

    const data = useTableData("case_question");
    const questions = useMemo(
        () => data.filter(q => q.case_id === caseId),
        [ data, caseId ]
    );

    if (questions.length === 0) {
        return null;
    }

    return (
        <List bordered className="case-questions-list">
            { questions.map(q => (
                <CaseQuestionsListItem 
                    key={ q.id }
                    caseId={ caseId }
                    question={ q }/>
            ))}
        </List>
    )
}

function CaseQuestions({ caseId }) {

    const [ showAddForm, toggleAddForm ] = useToggle(false);

    return (
        <div className="case-questions">
            
            { !showAddForm && 
                <>
                <Button type="primary" onClick={ toggleAddForm }>Добавить</Button>
                <br/><br/>
                </> }
            
            { showAddForm && 
                <>
                <QuestionForm 
                    title="Добавить вопрос" 
                    caseId={ caseId }
                    onSaved={ toggleAddForm }
                    onCancelled={ toggleAddForm }/>
                <br/>
                </> }
            <CaseQuestionsList caseId={ caseId }/>
        </div>
    )

}

export default CaseQuestions