import { useOn } from "@kuindji/observable-react";
import AppContext from "context/AppContext"
import { useCallback, useContext, useState } from "react"


function useTableData(name) {

    const app = useContext(AppContext);

    const [ data, setData ] = useState(() => app.get(`table-${ name }`) || []);

    const onChange = useCallback(
        () => setData(app.get(`table-${ name }`)),
        [ app, name ]
    );

    useOn(app, `table-${ name }`, onChange);

    return data;
}

export default useTableData