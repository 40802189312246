import { useCallback, useContext, useEffect, useMemo } from "react"
import { BrowserRouter } from "react-router-dom"
import { SessionContextProvider, useUser } from "@supabase/auth-helpers-react"

import Context from "lib/Context"
import AppContext from "context/AppContext"

import AppView from "views/AppView"
import AuthView from "views/AuthView"
import LoadingView from "views/LoadingView"

import useKey from "hooks/useKey"
import supabase from "lib/supabase"

function App() {

    const app = useContext(AppContext);
    const loading = useKey("loading", app);
    const loggedIn = useKey("loggedIn", app);
    const user = useUser();

    const checkCurrentUser = useCallback(
        async () => {
            const { data } = await supabase.auth.getSession();
            app.set("loggedIn", !!data.session);
            app.set("loading", false);
        },
        [ app ]
    );
    
    useEffect(
        () => {
            if (user && user.aud === "authenticated") {
                app.set("loggedIn", true);
                app.set("loading", false);
            }
        },
        [ user, app ]
    );
    useEffect(
        () => {
            checkCurrentUser();
        },
        // eslint-disable-next-line
        []
    );

    return loading ?
                <LoadingView/> :
                loggedIn ? 
                    <AppView/> : 
                    <AuthView/>;
}

function AppProdivers() {
    const app = useMemo(
        () => new Context({ loading: true, loggedIn: false }), 
        []
    );
    return (
        <AppContext.Provider value={ app }>
        <SessionContextProvider supabaseClient={ supabase }>
        <BrowserRouter basename="/">
            <App/>
        </BrowserRouter>
        </SessionContextProvider>
        </AppContext.Provider>
    );
}

export default AppProdivers;
