import { useCallback, useMemo, useState } from "react"
import { Button, List, Typography, Input, Tag, Popconfirm } from "antd"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import PrepostpositionSelector from "../PrepostpositionSelector"

import useTableData from "hooks/useTableData"

import supabase from "lib/supabase"
import useUpdateEffect from "hooks/useUpdateEffect"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"

const { Title } = Typography;

function CaseExampleForm({ title, example, table, caseId, wordId, onSaved, onCancelled }) {

    const { examplesTable, relField } = useMemo(
        () => ({
            examplesTable: `${ table }_case_example`,
            relField: `${ table }_id`
        }),
        [ table ]
    );
    const [ data, setData ] = useState(() => example?.example || {});
    const [ ppId, setPpid ] = useState(() => example?.prepostposition_id || null);
    const [ saving, setSaving ] = useState(false);

    useUpdateEffect(
        () => {
            setData(example?.example || {});
            setPpid(example?.prepostposition_id || null);
        },
        [ example ]
    );

    const changeField = useCallback(
        (name, value) => {
            setData(prev => {
                const data = { ...prev };
                if (name.indexOf("comment_") === 0) {
                    if (!data["comment"]) {
                        data["comment"] = {};
                    }
                    data["comment"][name.replace("comment_", "")] = value;
                }
                else {
                    data[name] = value;
                }
                return data;
            })
            
        },
        []
    );

    const handleArmenian = useCallback(
        (e) => changeField("armenian", e.target.value),
        [ changeField ]
    );
    const handleInformal = useCallback(
        (e) => changeField("informal", e.target.value),
        [ changeField ]
    );
    const handleTranscription = useCallback(
        (e) => changeField("transcription", e.target.value),
        [ changeField ]
    );
    const handleRussian = useCallback(
        (e) => changeField("russian", e.target.value),
        [ changeField ]
    );
    const handleEnglish = useCallback(
        (e) => changeField("english", e.target.value),
        [ changeField ]
    );
    const handleCommentRussian = useCallback(
        (e) => changeField("comment_russian", e.target.value),
        [ changeField ]
    );
    const handleCommentEnglish = useCallback(
        (e) => changeField("comment_english", e.target.value),
        [ changeField ]
    );

    const onSubmit = useCallback(
        async () => {

            setSaving(true);
            if (example && example.id) {
                const payload = { example: data, prepostposition_id: ppId };
                await supabase.from(examplesTable).update(payload).eq("id", example.id);
            }
            else {
                await supabase.from(examplesTable).insert({
                    case_id: caseId,
                    [relField]: wordId,
                    example: data,
                    prepostposition_id: ppId
                });
                setData({});
                setPpid(null);
            }
            onSaved && onSaved();
            setSaving(false);
        },
        [ data, example, examplesTable, relField, wordId, caseId, ppId, onSaved ]
    );

    const onCancel = useCallback(
        () => onCancelled && onCancelled(),
        [ onCancelled ]
    );

    return (
        <div className="case-example-form">
            { title && <Title level={ 5 }>Новый пример</Title> }
            <PrepostpositionSelector allowClear onChange={ setPpid } value={ ppId }/>
            <br/>
            <Input 
                placeholder="Հայերեն" 
                value={ data.armenian || "" } 
                disabled={ saving }
                onChange={ handleArmenian }/>
            <br/><br/>
            <Input 
                placeholder="Հայերեն разговорный" 
                value={ data.informal || "" } 
                disabled={ saving }
                onChange={ handleInformal }/>
            <br/><br/>
            <Input 
                placeholder="Транскрипция" 
                value={ data.transcription || "" } 
                disabled={ saving }
                onChange={ handleTranscription }/>
            <br/><br/>
            <div>
                <div>
                    
                    <Input 
                        placeholder="Пример" 
                        value={ data.russian || "" } 
                        disabled={ saving }
                        onChange={ handleRussian }/>
                    <br/><br/>
                    <Input 
                        placeholder="Комментарий" 
                        value={ data.comment?.russian || "" } 
                        disabled={ saving }
                        onChange={ handleCommentRussian }/>
                </div>
                <div>
                    <Input 
                        placeholder="Example" 
                        value={ data.english || "" } 
                        disabled={ saving }
                        onChange={ handleEnglish }/>
                    <br/><br/>
                    <Input 
                        placeholder="Comment" 
                        value={ data.comment?.english || "" } 
                        disabled={ saving }
                        onChange={ handleCommentEnglish }/>
                </div>
            </div>
            <br/>
            <Button 
                type="primary" 
                onClick={ onSubmit } 
                loading={ saving }
                disabled={ saving }>Сохранить</Button>
        
            <Button 
                type="secondary"
                onClick={ onCancel }
                disabled={ saving }>
                Отменить
            </Button>
        </div>
    )

}

function CaseExample({ example, caseId, wordId, table }) {

    const examplesTable = useMemo(() => `${ table }_case_example`, [ table ]);
    const pps = useTableData("prepostposition");
    const [ editmode, setEditmode ] = useState(false);
    const pp = useMemo(
        () => pps.find(pps => pps.id === example.prepostposition_id),
        [ example, pps ]
    );
    const content = useMemo(
        () => {
            const data = [
                example?.example?.armenian,
                example?.example?.russian,
                example?.example?.comment?.russian
            ];
            return data.filter(t => !!t).join(" - ");
        },
        [ example?.example ]
    );

    const onEditClick = useSwallowEventCallback(
        () => setEditmode(true),
        []
    );

    const onDelete = useCallback(
        async () => {
            await supabase.from(examplesTable).delete().eq("id", example.id);
        },
        [ examplesTable, example ]
    );
    
    return (
        <List.Item>
            { editmode ? 
                <CaseExampleForm 
                    onSaved={ () => setEditmode(false) }
                    onCancelled={ () => setEditmode(false) }
                    example={ example } 
                    caseId={ caseId } 
                    wordId={ wordId } 
                    table={ table }/> :
                <>
                <div>
                    { content }
                </div>
                { pp ? 
                    <Tag>
                        { pp.name.armenian } /{" "}
                        { pp.name.russian }
                    </Tag> :
                    null }
                <a href="/#" onClick={ onEditClick }>
                    <EditOutlined/>
                </a>
                <Popconfirm title="Вы уверены?" onConfirm={ onDelete }>
                    <DeleteOutlined/>
                </Popconfirm>
                </> }
            
        </List.Item>
    )
}

function CaseExamples({ table, wordId, caseId }) {

    const [ showNewCase, setShowNewCase ] = useState(false);
    const { examplesTable, relField } = useMemo(
        () => ({
            examplesTable: `${ table }_case_example`,
            relField: `${ table }_id`
        }),
        [ table ]
    );
    const data = useTableData(examplesTable);
    const examples = useMemo(
        () => data.filter(e => e[relField] === wordId && e.case_id === caseId),
        [ data, wordId, caseId, relField ]
    );

    const toggleShowNewCase = useSwallowEventCallback(
        () => setShowNewCase(prev => !prev),
        []
    );

    return (
        <div className="case-examples">
            <Title level={ 5 }>
                Примеры
                <Button type="primary" onClick={ toggleShowNewCase }>Добавить</Button>
            </Title>
            { showNewCase && 
                <>
                <CaseExampleForm 
                title="Новый пример" 
                table={ table } 
                onCancelled={ toggleShowNewCase }
                caseId={ caseId } 
                wordId={ wordId }/>
                <br/>
                </> }
            
            { examples.length > 0 && 
                <List bordered size="small" className="examples-list">
                    { examples.map(e => <CaseExample 
                        key={ e.id } 
                        example={ e }
                        table={ table }
                        wordId={ wordId }
                        caseId={ caseId }/> )}
                </List> }
        </div>
    )
}

export default CaseExamples