import { ReactComponent as Loading } from "svg/loading.svg"

function LoadingView() {
    return (
        <div className="loading-view">
            <Loading/>
        </div>
    )
}

export default LoadingView