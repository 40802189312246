import { useState } from "react"

import ItemsList from "components/ItemsList"
import WordPageHeader from "components/word/WordPageHeader"
import CaseWord from "components/word/CaseWord"


function Questions() {

    const [ wordId, setWordId ] = useState(null);

    return (
        <div className="page page-nouns">
            <WordPageHeader title="Вопросительные слова" table="question"/>
            <div className="page-2col">
                <div>
                    <ItemsList  
                        size="normal"
                        table="question" 
                        selectedId={ wordId } 
                        onSelect={ setWordId }/>
                </div>
                <CaseWord 
                    wordId={ wordId } 
                    table="question"
                    withPlural={ true }
                    withDeclension={ false }/>
            </div>
        </div>
    )
}

export default Questions