import { useCallback, useContext, useEffect } from "react"
import supabase from "lib/supabase"


import AppContext from "context/AppContext"


function useTable(name, order) {

    const app = useContext(AppContext);

    const loadData = useCallback(
        async () => {
            app.set(`table-${ name }-loading`, true);
            let promise = supabase.from(name).select();
            if (order) {
                promise = promise.order(order.by, order.how);
            }
            const { data, error } = await promise;
            app.set(`table-${ name }`, data);
            app.set(`table-${ name }-loading`, false);
            app.set(`table-${ name }-error`, error);
        },
        [ app, name, order ]
    );

    useEffect(
        () => {
            loadData();

            const ch = supabase
                .channel(name)
                .on(
                    "postgres_changes", 
                    {
                        event: "*",
                        schema: "public",
                        table: name
                    },
                    loadData
                )
                .subscribe();

            return () => {
                supabase.removeChannel(ch);
            }
        },
        // eslint-disable-next-line
        []
    );
}

export default useTable