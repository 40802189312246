
import { Route, Routes, Navigate } from "react-router"
import TopMenu from "components/TopMenu"

import Nouns from "pages/Nouns"
import Pronouns from "pages/Pronouns"
import Numerals from "pages/Numerals"
import Questions from "pages/Questions"
import Cases from "pages/Cases"

import useTable from "hooks/useTable"

function AppView() {

    useTable("case", { by: "position", how: { ascending: true }});
    useTable("declension");
    useTable("prepostposition");

    useTable("noun");
    useTable("noun_case");
    useTable("noun_case_example");
    useTable("pronoun");
    useTable("pronoun_case");
    useTable("pronoun_case_example");
    useTable("numeral");
    useTable("numeral_case");
    useTable("numeral_case_example");
    useTable("question");
    useTable("question_case");
    useTable("question_case_example");

    useTable("case_noun_group");
    useTable("case_noun_group_noun");
    useTable("case_question");
    useTable("article", { by: "position", how: { ascending: true }});
   
    return (
        <div className="app-view">
            <TopMenu/>
            <Routes>
                <Route path="/" element={<Navigate to="/nouns" />} />
                <Route path="/nouns" element={ <Nouns/> }/>
                <Route path="/pronouns" element={ <Pronouns/> }/>
                <Route path="/numerals" element={ <Numerals/> }/>
                <Route path="/questions" element={ <Questions/> }/>
                <Route path="/cases" element={ <Cases/> }/>
            </Routes>
        </div>
    )
}

export default AppView