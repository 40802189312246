import { useCallback, useMemo, useState } from "react"
import { Button, Typography, Popconfirm } from "antd"

import WordForm from "components/word/WordForm"
import WordInfo from "components/word/WordInfo"
import DeclensionSelector from "components/DeclensionSelector"
import CaseTabs from "components/word/CaseTabs"

import supabase from "lib/supabase"
import useTableData from "hooks/useTableData"
import useUpdateEffect from "hooks/useUpdateEffect"
import CaseExamples from "./CaseExamples"

const { Title } = Typography;


function CaseWord({ wordId, table, withPlural = true, withDeclension = true, withExamples = true }) {

    const words = useTableData(table);
    const { caseRelTable, caseRelField } = useMemo(
        () => ({
            caseRelTable: `${ table }_case`,
            caseRelField: `${ table }_id`
        }),
        [ table ]
    );
    const cases = useTableData("case");
    const wordCases = useTableData(caseRelTable);
    const [ caseId, setCaseId ] = useState(() => cases[0]?.id);
    const wordCase = useMemo(
        () => wordCases.find(wc => wc.case_id === caseId && wc[caseRelField] === wordId) || {},
        [ wordId, caseId, wordCases, caseRelField ]
    );
    const [ declensionId, setDeclensionId ] = useState(() => wordCase.declension_id || null);
    const [ single, setSingle ] = useState({});
    const [ plural, setPlural ] = useState({});
    const [ saving, setSaving ] = useState(false);
    const [ word, setWord ] = useState(() => words.find(w => w.id === wordId));

    useUpdateEffect(
        () => {
            setSingle(wordCase.single || {});
            setPlural(wordCase.plural || {});
            setDeclensionId(wordCase.declension_id || null);
        },
        [ wordCase ]
    );

    useUpdateEffect(
        () => setWord(words.find(w => w.id === wordId)),
        [ wordId, words ]
    );

    const onTabChange = useCallback(
        (key) => setCaseId(key !== "info" ? key : null),
        []
    );

    const onDelete = useCallback(
        async () => {
            await supabase.from(table).delete().eq("id", wordId.trim());
        },
        [ wordId, table ]
    );

    const onSave = useCallback(
        async () => {
            setSaving(true);
            if (wordCase && wordCase.id) {
                const payload = { single, plural };
                if (withDeclension) {
                    payload.declension_id = declensionId;
                }
                await supabase.from(caseRelTable)
                                .update(payload)
                                .eq("id", wordCase.id);
            }
            else {
                const payload = {
                    case_id: caseId,
                    [caseRelField]: wordId,
                    single,
                    comment: {}
                };
                if (withPlural) {
                    payload.plural = plural;
                }
                if (withDeclension) {
                    payload.declension_id = declensionId;
                }
                await supabase.from(caseRelTable).insert(payload);
            }
            setSaving(false);
        },
        [ caseId, wordId, single, plural, wordCase, 
            declensionId, caseRelTable, caseRelField,
            withDeclension, withPlural ]
    );

    const onSaveInfo = useCallback(
        async () => {
            setSaving(true);
            const payload = {
                id: word.id,
                comment: word.comment || {},
                description: word.description || {}
            }
            await supabase.from(table).update(payload).eq("id", word.id);
            setSaving(false);
        },
        [ word, table ]
    );

    useUpdateEffect(
        () => {
            if (cases && cases.length > 0) {
                setCaseId(cases[0].id);
            }
        },
        [ cases, wordId ]
    );


    if (!wordId) {
        return null;
    }

    return (
        <div className="case-word">
            <CaseTabs 
                disabled={ !wordId }
                showGeneralInfo 
                onChange={ onTabChange } 
                currentTab={ caseId || "info" }/>

            { !caseId && 
                <>
                    <WordInfo 
                        word={ word } 
                        onChange={ setWord } 
                        saving={ saving }
                        showComment
                        showDescription/>
                    <br/>
                    <Button  
                        type="primary"
                        children="Сохранить" 
                        onClick={ onSaveInfo }
                        loading={ saving }
                        disabled={ saving }/> 
                    <br/><br/><br/>

                    <Popconfirm title="Вы уверены?" onConfirm={ onDelete }>
                        <Button 
                            danger 
                            children="Удалить слово" 
                            disabled={ !wordId }/>
                    </Popconfirm>
                </> }

            { caseId && 
                <div className="word-case">
                    <div>
                        { withDeclension && 
                        <>
                        <Title level={ 5 }>Склонение</Title>
                        <DeclensionSelector 
                            value={ declensionId } 
                            onChange={ setDeclensionId }
                            allowClear
                            disabled={ saving }/>
                        <br/><br/>
                        </> }

                        <div className="word-forms">
                            
                            <WordForm 
                                title={ withPlural ? "Единственное число" : null }
                                form={ single }
                                saving={ saving }
                                onChange={ setSingle }/> 

                            { withPlural && 
                                <WordForm 
                                    title="Множественное число" 
                                    form={ plural }
                                    saving={ saving }
                                    onChange={ setPlural }/> }
                        </div>
                        <br/>
                
                        <Button  
                            type="primary"
                            children="Сохранить" 
                            onClick={ onSave }
                            loading={ saving }
                            disabled={ saving }/> 
                    </div>  
                    <CaseExamples table={ table } wordId={ wordId } caseId={ caseId }/>
                </div> }
        </div>
    )
}

export default CaseWord