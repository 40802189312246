import { Auth } from "@supabase/auth-ui-react"
import { ThemeSupa } from "@supabase/auth-ui-shared"
import supabase from "lib/supabase"

function AuthView() {

    return (
        <div className="auth-view">
            <Auth 
                supabaseClient={ supabase }
                appearance={{ theme: ThemeSupa }}
                providers={[]}/>
        </div>
    )
}

export default AuthView