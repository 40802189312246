import { List } from "antd";
import useDictRef from "hooks/useDictRef";
import useSwallowEventCallback from "hooks/useSwallowEventCallback";
import useTableData from "hooks/useTableData"
import useUpdateEffect from "hooks/useUpdateEffect";
import { useMemo } from "react";



function ListItem({ item, onSelect, selected }) {

    const onItemClick = useSwallowEventCallback(
        () => onSelect(item.id),
        [ item, onSelect ]
    );

    return (
        <List.Item key={ item.id } className={ selected ? "active" : "" }>
            <a href="/#" onClick={ onItemClick }>
                { item.name }
            </a>
        </List.Item>
    )
}

function ItemsList({ table, onSelect, selectedId, ...rest }) {

    const data = useTableData(table);
    const { caseRelTable, caseRelField } = useMemo(
        () => ({
            caseRelTable: `${ table }_case`,
            caseRelField: `${ table }_id`
        }),
        [ table ]
    );
    const caseData = useTableData(caseRelTable);
    const items = useMemo(
        () => data.map(item => {
            const caseItem = caseData.find(c => c[caseRelField] === item.id && c.case_id === "nominative");
            item.name = caseItem?.single?.russian || item.id;
            return item;
        }),
        [ data, caseData, caseRelField ]
    );
    const ref = useDictRef({ selectedId, onSelect });

    useUpdateEffect(
        () => {
            if (ref.selectedId && !data.find(item => item.id === ref.selectedId)) {
                onSelect(null);
            }
        },
        [ data ]
    );

    return (
        <List size="small" className="items-list" { ...rest }>
            { items.map(item => (
                <ListItem 
                    key={ item.id } 
                    item={ item } 
                    onSelect={ onSelect } 
                    selected={ selectedId === item.id }/>
            ))}
        </List>
    )
}

export default ItemsList