import { useCallback } from "react"
import { Input, Typography } from "antd"


const { Title } = Typography;


function WordInfo({ word, onChange, saving = false, 
                    showName = false, 
                    showDescription = false, 
                    showComment = false }) {

    const onFieldChange = useCallback(
        (field, language, value) => {
            const w = { ...word };
            w[field][language] = value;
            onChange(w);
        },
        [ word, onChange ]
    );

    const handleNameRussian = useCallback(
        (e) => onFieldChange("name", "russian", e.target.value),
        [ onFieldChange ]
    );

    const handleNameEnglish = useCallback(
        (e) => onFieldChange("name", "english", e.target.value),
        [ onFieldChange ]
    );

    const handleDescriptionRussian = useCallback(
        (e) => onFieldChange("description", "russian", e.target.value),
        [ onFieldChange ]
    );

    const handleDescriptionEnglish = useCallback(
        (e) => onFieldChange("description", "english", e.target.value),
        [ onFieldChange ]
    );

    const handleCommentRussian = useCallback(
        (e) => onFieldChange("comment", "russian", e.target.value),
        [ onFieldChange ]
    );

    const handleCommentEnglish = useCallback(
        (e) => onFieldChange("comment", "english", e.target.value),
        [ onFieldChange ]
    );

    return (
        <div className="word-info">
        { showName && 
            <section>
                <Title level={ 5 }>Название</Title>
                <Input 
                    placeholder="Название" 
                    value={ word?.name?.russian || "" } 
                    onChange={ handleNameRussian }
                    disabled={ saving }/>
                <br/><br/>
                <Input 
                    placeholder="Name" 
                    value={ word?.name?.english || "" } 
                    onChange={ handleNameEnglish }
                    disabled={ saving }/>
            </section>}

        { showDescription && 
            <section>
                <Title level={ 5 }>Описание</Title>
                <Input 
                    placeholder="Описание" 
                    value={ word?.description?.russian || "" } 
                    onChange={ handleDescriptionRussian }
                    disabled={ saving }/>
                <br/><br/>
                <Input 
                    placeholder="Description" 
                    value={ word?.description?.english || "" } 
                    onChange={ handleDescriptionEnglish }
                    disabled={ saving }/>
            </section> }
        

        { showComment && 
            <section>
                <Title level={ 5 }>Комментарий</Title>
                <Input 
                    placeholder="Комментарий" 
                    value={ word?.comment?.russian || "" } 
                    onChange={ handleCommentRussian }
                    disabled={ saving }/>
                <br/><br/>
                <Input 
                    placeholder="Comment" 
                    value={ word?.comment?.english || "" } 
                    onChange={ handleCommentEnglish }
                    disabled={ saving }/>
            </section> }
        </div>
    )
}

export default WordInfo