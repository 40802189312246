import { Popconfirm, Typography, Input, Button, List, Tag } from "antd"
import { EditOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons"

import DeclensionSelector from "components/DeclensionSelector"
import useTableData from "hooks/useTableData"
import useUpdateEffect from "hooks/useUpdateEffect"
import supabase from "lib/supabase"
import { useCallback, useMemo, useState } from "react"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import useToggle from "hooks/useToggle"
import WordSelector from "components/WordSelector"

const { Title } = Typography;

function getDefaultData(group) {
    return {
        name: {
            russian: "",
            egnlish: "",
            ...group?.name
        },
        comment: {
            russian: "",
            egnlish: "",
            ...group?.comment
        },
        description: {
            russian: "",
            egnlish: "",
            ...group?.description
        }
    }
}

function GroupForm({ title, caseId, group, onSaved, onCancelled, onClose }) {

    const [ saving, setSaving ] = useState(false);
    const [ declesionId, setDeclensionId ] = useState(() => group?.declension_id);
    const [ data, setData ] = useState(() => getDefaultData(group));
    const wordsData = useTableData("case_noun_group_noun");
    const nounCaseData = useTableData("noun_case");
    const words = useMemo(
        () => wordsData
            .filter(wd => wd.case_noun_group_id === group?.id)
            .map(wd => ({
                ...wd,
                name: nounCaseData
                        .find(nc => nc.case_id === "nominative" && nc.noun_id === wd.noun_id)?.single?.russian
            })),
        [ wordsData, nounCaseData, group ]
    );

    useUpdateEffect(
        () => {
            setData(getDefaultData(group));
            setDeclensionId(group?.declension_id);
        },
        [ group ]
    )

    const onNChange = useCallback(
        (name, value) => {
            setData(prev => ({
                ...prev,
                name: {
                    ...prev.name,
                    [name]: value
                }
            }))
        },
        []
    );
    const onCChange = useCallback(
        (name, value) => {
            setData(prev => ({
                ...prev,
                comment: {
                    ...prev.comment,
                    [name]: value
                }
            }))
        },
        []
    );
    const onDChange = useCallback(
        (name, value) => {
            setData(prev => ({
                ...prev,
                description: {
                    ...prev.description,
                    [name]: value
                }
            }))
        },
        []
    );

    const handleNRussianChange = useCallback(
        (e) => onNChange("russian", e.target.value),
        [ onNChange ]
    );
    const handleNEnglishChange = useCallback(
        (e) => onNChange("english", e.target.value),
        [ onNChange ]
    );

    const handleCRussianChange = useCallback(
        (e) => onCChange("russian", e.target.value),
        [ onCChange ]
    );
    const handleCEnglishChange = useCallback(
        (e) => onCChange("english", e.target.value),
        [ onCChange ]
    );

    const handleDRussianChange = useCallback(
        (e) => onDChange("russian", e.target.value),
        [ onDChange ]
    );
    const handleDEnglishChange = useCallback(
        (e) => onDChange("english", e.target.value),
        [ onDChange ]
    );

    const onSaveClick = useCallback(
        async () => {
            setSaving(true);

            const payload = {
                case_id: caseId,
                name: data.name,
                comment: data.comment,
                description: data.description,
                declension_id: declesionId
            };
            if (group && group.id) {  
                await supabase.from("case_noun_group").update(payload).eq("id", group.id);
            }
            else {
                await supabase.from("case_noun_group").insert(payload);
            }

            setData({ question: {}, comment: {}});
            setDeclensionId(null);
            setSaving(false);
            onSaved && onSaved();
        },
        [ group, data, declesionId, onSaved, caseId ]
    );

    const onCancelClick = useCallback(
        () => onCancelled && onCancelled(),
        [ onCancelled ]
    );

    const onCloseClick = useSwallowEventCallback(
        () => onClose && onClose(),
        [ onClose ]
    );

    const addWord = useCallback(
        async (id) => {
            if (!wordsData.find(wd => wd.noun_id === id && wd.case_noun_group_id === group.id)) {
                await supabase.from("case_noun_group_noun").insert({
                    case_noun_group_id: group.id,
                    noun_id: id
                });
            }
        },
        [ wordsData, group ]
    );

    const removeWord = useCallback(
        async (e, id) => {
            e.preventDefault();
            e.stopPropagation();
            await supabase.from("case_noun_group_noun").delete().eq("id", id);
        },
        []
    );

    return (
        <div className="case-group-form">
            { title && <Title level={ 5 }>{ title }</Title> }
            <a href="/#" className="case-group-form-close" onClick={ onCloseClick }>
                <CloseOutlined/>
            </a>
            <div className="case-group-form-layout">
                <div>
                    <DeclensionSelector 
                        value={ declesionId } 
                        onChange={ setDeclensionId } 
                        allowClear/>
                    <br/><br/>
                    <Input
                        placeholder="Название"
                        value={ data.name?.russian }
                        onChange={ handleNRussianChange }
                        disabled={ saving }/>
                    <br/><br/>
                    <Input
                        placeholder="Описание"
                        value={ data.description?.russian }
                        onChange={ handleDRussianChange }
                        disabled={ saving }/>
                    <br/><br/>
                    <Input
                        placeholder="Комментарий"
                        value={ data.comment?.russian }
                        onChange={ handleCRussianChange }
                        disabled={ saving }/>
                    <br/><br/>

                    <Input
                        placeholder="Name"
                        value={ data.name?.english }
                        onChange={ handleNEnglishChange }
                        disabled={ saving }/>
                    <br/><br/>
                    <Input
                        placeholder="Description"
                        value={ data.description?.english }
                        onChange={ handleDEnglishChange }
                        disabled={ saving }/>
                    <br/><br/>
                    <Input
                        placeholder="Comment"
                        value={ data.comment?.english }
                        onChange={ handleCEnglishChange }
                        disabled={ saving }/>
                    <br/><br/>
                    <Button 
                        type="primary" 
                        onClick={ onSaveClick }
                        loading={ saving }
                        disabled={ saving }>Сохранить</Button>
                    <Button 
                        type="secondary" 
                        onClick={ onCancelClick }
                        disabled={ saving }>Отмена</Button>
                </div>
                { group?.id && 
                    <>
                    <div>
                        <Title level={ 5 }>Добавить слово</Title>
                        <WordSelector allowClear onChange={ addWord } value=""/>
                        <List>
                            { words.map(w => (
                                <div key={ w.id } className="ant-list-item">
                                    { w.name || w.noun_id }
                                    <a href="/#" onClick={ (e) => removeWord(e, w.id) }>
                                        <DeleteOutlined/>
                                    </a>
                                </div>
                            ))}
                        </List>
                    </div>
                    </>}
                
            </div>
        </div>
    )
}

function CaseGroupsListItem({ caseId, group }) {

    const [ editMode, toggleEditMode ] = useToggle(false);

    const onEditClick = useSwallowEventCallback(
        () => toggleEditMode(),
        []
    );

    const onDelete = useCallback(
        async () => {
            await supabase.from("case_noun_group").delete().eq('id', group.id);
        },
        [ group ]
    );

    return (
        <List.Item>
            { editMode ? 
                <GroupForm 
                    group={ group } 
                    caseId={ caseId }
                    onClose={ toggleEditMode }
                    onCancelled={ toggleEditMode }/> :
                <>
                <div>
                    { group.name.russian }
                    { group.comment.russian ? " (" + group.comment.russian + ")" : null }
                    <br/>
                    { group.description.russian }
                </div>

                { group.declension_id && <Tag>{ group.declension_id }</Tag>}

                <a href="/#" onClick={ onEditClick }>
                    <EditOutlined/>
                </a>
                <Popconfirm title="Вы уверены?" onConfirm={ onDelete }>
                    <DeleteOutlined/>
                </Popconfirm>
                </>  }
        </List.Item>
    )
}

function CaseGroupsList({ caseId }) {

    const data = useTableData("case_noun_group");
    const groups = useMemo(
        () => data.filter(q => q.case_id === caseId),
        [ data, caseId ]
    );

    if (groups.length === 0) {
        return null;
    }

    return (
        <List bordered className="case-groups-list">
            { groups.map(g => (
                <CaseGroupsListItem 
                    key={ g.id }
                    caseId={ caseId }
                    group={ g }/>
            ))}
        </List>
    )
}

function CaseGroups({ caseId }) {
    const [ showAddForm, toggleAddForm ] = useToggle(false);

    return (
        <div className="case-groups">
            { !showAddForm && 
                <>
                <Button type="primary" onClick={ toggleAddForm }>Добавить</Button>
                <br/><br/>
                </> }
            { showAddForm && 
                <>
                <GroupForm
                    title="Добавить группу" 
                    caseId={ caseId }
                    onSaved={ toggleAddForm }
                    onClose={ toggleAddForm }
                    onCancelled={ toggleAddForm }/>
                <br/>
                </> }
            <CaseGroupsList caseId={ caseId }/>
        </div>
    )
}

export default CaseGroups