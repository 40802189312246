import { useMemo } from "react"
import { Select } from "antd"

import useTableData from "hooks/useTableData"

function PrepostpositionSelector({ value, onChange, ...rest }) {
    const ppositions = useTableData("prepostposition");
    const ppositionOptions = useMemo(
        () => ppositions.map(pp => ({ 
            value: pp.id, 
            label: pp.name.armenian +" - "+ pp.name.russian 
        })),
        [ ppositions ]
    );
    
    return (
        <Select 
            placeholder="Pre/post position"
            value={ value }
            options={ ppositionOptions } 
            onChange={ onChange }
            { ...rest }/>
    )
}

export default PrepostpositionSelector