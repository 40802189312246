import { useState, useCallback } from "react"
import { Input, Button, Typography, Tooltip } from "antd"

import supabase from "lib/supabase"

const { Title } = Typography;

function WordPageHeader({ title, table }) {

    const [ newWord, setNewWord ] = useState("");
    const [ adding, setAdding ] = useState(false);
    const handleChange = useCallback(
        (e) => setNewWord((e.target.value || "").toLowerCase().replace(/[^a-z0-9_\-\/]/i, "")),
        []
    );

    const onAddClick = useCallback(
        async () => {
            setNewWord("");
            setAdding(true);
            await supabase.from(table).insert({ id: newWord });
            setAdding(false);
        },
        [ newWord, table ]
    );

    const onKeydown = useCallback(
        (e) => e.code === "Enter" && onAddClick(),
        [ onAddClick ]
    );
    
    return (
        <div className="page-header">
            <Title level={ 3 }>{ title }</Title>
            <Tooltip title="Слово на английском без пробелов маленькими буквами">
                <Input 
                    placeholder="Идентификатор нового слова"
                    value={ newWord } 
                    onChange={ handleChange } 
                    onKeyDown={ onKeydown }/>
            </Tooltip>
            <Button 
                children="Добавить" 
                onClick={ onAddClick }
                disabled={ adding }
                loading={ adding }/>
        </div>
    )
}

export default WordPageHeader