import { Select } from "antd"

const typeOptions = [
    {
        value: "noun",
        label: "Для существительных"
    },
    {
        value: "pronoun",
        label: "Для местоимений"
    }
]

function TypeSelector({ value, onChange, ...rest }) {
    return (
        <Select
            placeholder="Для"
            options={ typeOptions }
            value={ value }
            onChange={ onChange }
            { ...rest }/>
    )
}

export default TypeSelector