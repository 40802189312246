import { NavLink, useLocation } from "react-router-dom"
import { Menu } from "antd"

function TopMenu() {

    const location = useLocation();
    const path = location.pathname;

    return (
        <div className="top-menu">
            <Menu activeKey={ path } mode="horizontal">
                <Menu.Item key="/nouns">
                    <NavLink to="/nouns" children="Существительные"/>
                </Menu.Item>
                <Menu.Item key="/pronouns">
                    <NavLink to="/pronouns" children="Местоимения"/>
                </Menu.Item>
                <Menu.Item key="/numerals">
                    <NavLink to="/numerals" children="Числительные"/>
                </Menu.Item>
                <Menu.Item key="/questions">
                    <NavLink to="/questions" children="Вопросительные слова"/>
                </Menu.Item>
                <Menu.Item key="/cases">
                    <NavLink to="/cases" children="Падежи"/>
                </Menu.Item>
            </Menu>
        </div>
    )
}

export default TopMenu