import { Select } from "antd"

const typeOptions = [
    {
        value: "russian",
        label: "Русский"
    },
    {
        value: "english",
        label: "English"
    }
]

function LanguageSelector({ value, onChange, ...rest }) {
    return (
        <Select
            placeholder="Язык"
            options={ typeOptions }
            value={ value }
            onChange={ onChange }
            { ...rest }/>
    )
}

export default LanguageSelector