import { useCallback } from "react"
import { Input, Typography } from "antd"
import useDictRef from "hooks/useDictRef";

const { Title } = Typography;


function WordForm({ title, form, onChange, saving = false }) {

    const { russian, english, transcription, armenian, informal, comment = {} } = form;
    const { russian: commentRussian, english: commentEnglish } = comment;
    const ref = useDictRef({ form, onChange });

    const onFieldChange = useCallback(
        (field, value) => {
            const form = { ...ref.form };
            if (field === "commentRussian" || field === "commentEnglish") {
                const comment = { ...form.comment };
                comment[field.replace("comment", "").toLowerCase()] = value;
                form.comment = comment;
            }
            else {
                form[field] = value; 
            }
            onChange(form);
        },
        // eslint-disable-next-line
        [ onChange ]
    );

    const handleArmenian = useCallback(
        (e) => onFieldChange("armenian", e.target.value),
        [ onFieldChange ]
    );
    const handleInformal = useCallback(
        (e) => onFieldChange("informal", e.target.value),
        [ onFieldChange ]
    );
    const handleRussian = useCallback(
        (e) => onFieldChange("russian", e.target.value),
        [ onFieldChange ]
    );
    const handleEnglish = useCallback(
        (e) => onFieldChange("english", e.target.value),
        [ onFieldChange ]
    );
    const handleTranscription = useCallback(
        (e) => onFieldChange("transcription", e.target.value),
        [ onFieldChange ]
    );
    const handleCommentRussian = useCallback(
        (e) => onFieldChange("commentRussian", e.target.value),
        [ onFieldChange ]
    );
    const handleCommentEnglish = useCallback(
        (e) => onFieldChange("commentEnglish", e.target.value),
        [ onFieldChange ]
    );

    /*const onSaveClick = useCallback(
        async () => {
            setSaving(true);
            const value = { 
                armenian,
                russian, 
                english, 
                transcription, 
                comment: {
                    russian: commentRussian,
                    english: commentEnglish
                }};
            await onSave(value);
            setSaving(false);
        },
        [ armenian, russian, english, transcription, 
            commentRussian, commentEnglish, onSave ]
    );*/

    return (
        <div className="word-form">
            { title && <Title level={ 5 }>{ title }</Title> }
            <Input 
                placeholder="Հայերեն" 
                value={ armenian } 
                onChange={ handleArmenian }
                disabled={ saving }/>
            <br/><br/>
            <Input 
                placeholder="Հայերեն (разговорный)" 
                value={ informal } 
                onChange={ handleInformal }
                disabled={ saving }/>
            <br/><br/>
            <Input 
                placeholder="Транскрипция" 
                value={ transcription } 
                onChange={ handleTranscription }
                disabled={ saving }/>
            <br/><br/>
            <Input 
                placeholder="Русский язык" 
                value={ russian } 
                onChange={ handleRussian }
                disabled={ saving }/>
            <br/><br/>
            <Input 
                placeholder="Комментарий" 
                value={ commentRussian } 
                onChange={ handleCommentRussian }
                disabled={ saving }/>
            <br/><br/>
            <Input 
                placeholder="English" 
                value={ english } 
                onChange={ handleEnglish }
                disabled={ saving }/>
            <br/><br/>
            <Input 
                placeholder="Comment" 
                value={ commentEnglish } 
                onChange={ handleCommentEnglish }
                disabled={ saving }/>
        </div>
    )
}

export default WordForm